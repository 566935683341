import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

class Migration extends React.Component {
  script

  componentDidMount () {
    this.script = document.createElement("script");
    this.script.src = "/migration-interactive/main.js";
    this.script.async = true;
    document.body.appendChild(this.script);
  }

  componentWillUnmount () {
    window.unmountMigrationInteractive()
    document.body.removeChild(this.script)
  }

  render () {
    const { location, data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const title = `${siteTitle} – Predicting Migration in Response To Crisis`

    return (
      <Layout location={location}>
        <Helmet>
          <title>{title}</title>
          <meta property="og:url" content={`${process.env.ROOT_URL}/migration/`} />
          <meta property="og:image" content={`${process.env.ROOT_URL}/migration.png`} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content="If aid organizations can predict forced migrations, they can respond quickly and effectively. So we're creating a model to simulate forced migration away from a crisis event." />
        </Helmet>
        <div
          id="migration-interactive"
          data-path="/migration-interactive/"
          data-header-height="150"
          className="-mx-8"></div>
      </Layout>
    )
  }
}


export const postQuery = graphql`
  query Migration {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Migration
